// Text Objects
/* eslint-disable key-spacing */
/* eslint-disable quote-props */

// Localized React Components
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import paths from './paths';

export const defaultLanguage = 'de';

export const languages = [
  // 'en',
  'de',
  // 'fr',
  // 'it',
];

const translations = {
  // --- common ---
  'accessCode': {
    'de': 'Zugangscode',
    'en': 'Access Code',
  },
  'accessCode.your': {
    'de': 'Ihr Zugangscode:',
    'en': 'Your Access Code:',
  },
  'back': {
    'de': 'zurück',
    'en': 'back',
  },
  'continue': {
    'de': 'weiter',
    'en': 'continue',
  },
  'email': {
    'de': 'E-Mail Adresse',
    'en': 'E-Mail Address',
  },
  'fillIn': {
    'de': 'ausfüllen',
    'en': 'fill in',
  },
  'included': {
    'de': 'inklusive',
    'en': 'included',
  },
  'makeAppointment': {
    'de': 'Termin vereinbaren',
    'en': 'make an appointment',
  },
  'noScenes': {
    'de': 'Dieses Projekt enthält keine Szenen.',
    'en': 'There are no scenes in this project.',
  },
  'notes': {
    'de': 'Notizen',
    'en': 'Notes',
  },
  'ok': {
    'de': 'OK',
    'en': 'OK',
  },
  'print': {
    'de': 'drucken',
    'en': 'print',
  },
  'start': {
    'de': 'starten',
    'en': 'start',
  },
  'startAgain': {
    'de': 'neu starten',
    'en': 'start again',
  },
  'send': {
    'de': 'senden',
    'en': 'send',
  },

  // --- topics ---

  'cart.button': {
    'de': 'Warenkorb',
    'en': 'Cart',
  },
  'cart.bar': {
    'de': 'Warenkorb',
    'en': 'Cart',
  },
  'cart.section.common': {
    'de': 'Allgemeine Optionen',
    'en': 'Common Options',
  },
  'cart.total.title': {
    'de': 'Zusatzkosten aus individueller Wohnungskonfiguration Total inkl. MWST',
    'en': 'Total Options inc. VAT',
  },
  'cart.total.disclaimer': {
    'de': 'Hinweis: Die Mehrkosten gemäss Ihrer individuellen Wohnungskonfiguration werden dem Basiskaufpreis der Wohnung hinzugerechnet.',
  },
  'contract.title': {
    'de': 'Ihre individuelle Wohnungskonfiguration',
    'en': 'Your individual configuration',
  },
  'contract.subtitle': {
    'de': 'In Ergänzung zum Käuferbaubeschrieb haben sie folgende Konfiguration vorgenommen:',
  },
  'contract.disclaimer': {
    'de': 'Die Inhalte dieser Seiten wurden mit grösstmöglicher Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität übernimmt die Verkäuferin keine Haftung. Bei nicht mehr erhältlichen Zulieferprodukte werden automatisch die vom Hersteller gelieferten Nachfolge-Produkte eingesetzt. Die gezeigten Visualisierungen entsprechen einer genäherten Realität; Abweichungen im Farbton und in der konkreten Ausgestaltung bleiben ausdrücklich vorbehalten. Die vorliegende individuelle Wohnungskonfiguration ist für die Käuferschaft verbindlich, erlangt für die Verkäuferin jedoch erst mit öffentlicher Beurkundung des Kaufvertrages Gültigkeit.',
  },
  'contract.signature.buyer': {
    'de': 'Datum / Unterschrift Käufer:in',
    'en': 'Date / Signature Buyer',
  },
  'contract.signature.seller': {
    'de': 'Datum / Unterschrift Verkäufer:in',
    'en': 'Date / Signature Seller',
  },
  // // floorplan
  // 'floorplan.missingNr.title': {
  //   'de': 'Grundriss nicht gefunden',
  //   'en': 'Floorplan not found',
  // },
  // 'floorplan.missingNr.help': {
  //   'de': (<>
  //     Der Link enthält keine Die Wohnungsnummer fehlt:<br />
  //     konfigurator.tend.ch/view/example/1?code=AAAAAA<u>&nr=Abc</u>
  //   </>),
  //   'en': (<>
  //     The apartment number is missing:<br />
  //     konfigurator.tend.ch/view/example/1?code=AAAAAA<u>&nr=Abc</u>
  //   </>),
  // },
  // folderStructure
  'folderStructure.title': {
    'de': 'Ordnerstruktur',
    'en': 'Folder structure',
  },
  'folderStructure.matches.description': {
    'de': (<>
      Der Konfigurator versucht die einzelnen Varianten aus der Datei zu lesen.
      Das klappt manchmal nicht, wenn die Dateien oder Varianten falsch benannt sind.<br />
      <br />
      Manchmal hat es bewusst Bilder von alten oder versteckten Varianten,
      diese Warnungen können ignoriert werden.<br />
      <br />
      Auf der rechten Seite wird gezeigt, welcher Teil des Dateinamens nicht erkannt
      werden konnte.
    </>),
    'en': (<>
      The configurator tries to read the individual variants from the file.
      Sometimes this does not work if the files or variants are incorrectly named.<br />
      <br />
      Sometimes it has images of old or hidden variants on purpose,
      these warnings can be ignored.<br />
      <br />
      On the right side it shows which part of the filename could not be recognized.
    </>),
  },
  'folderStructure.matches.all': {
    'de': 'Bilder vollständig erkannt',
    'en': 'Images fully recognized',
  },
  'folderStructure.matches.missing': {
    'de': 'Bilder nur teilweise erkannt',
    'en': 'Images only partially recognized',
  },
  'folderStructure.matches.path': {
    'de': 'Datei',
    'en': 'File',
  },
  'folderStructure.matches.unmatched': {
    'de': 'nicht erkannt',
    'en': 'not recognized',
  },
  'folderStructure.duplicates.description': {
    'de': (<>
      Weil die Varianten aus den Dateinamen ausgelesen werden, kann es Verwechslungen geben,
      wenn verschiedene Optionen Varianten mit dem gleichen Namen haben.
      Auch wenn ein Name einer Variante vollständig im Namen einer anderen Variante vorkommt,
      kann dies passieren.<br />
      <br />
      Wenn eine Variante von zwei Optionen gewählt werden kann, dann ist diese doppelte
      Verwendung des Namens sogar notwendig.<br />
      <br />
      Rechts werden die doppelten file_names von Varianten angezeigt.
    </>),
    'en': (<>
      Because the variants are read from the file names, there can be confusion
      when different options have variants with the same name.
      Even if a name of a variant is fully contained in the name of another variant,
      this can happen.<br />
      <br />
      If a variant can be chosen by two options, then this double
      use of the name is even necessary.<br />
      <br />
      On the right side the duplicate file_names of variants are shown.
    </>),
  },
  'folderStructure.duplicates.all': {
    'de': 'Varianten mit eindeutigen file_names',
    'en': 'Variants with unique file_names',
  },
  'folderStructure.duplicates.missing': {
    'de': 'Varianten mit doppelten file_names',
    'en': 'Variants with duplicate file_names',
  },
  'folderStructure.images.description': {
    'de': (<>
      Eine Übersicht, welche Varianten Zuordnungen zu Bildern haben.<br />
      <br />
      Bei Varianten welche nicht visualisiert werden, fehlt die Zuordnung entsprechend.<br />
      <br />
      Rechts werden die file_names angezeigt, welche in den Dateinamen der Bilder gesucht wurden.
    </>),
    'en': (<>
      An overview of which variants have assignments to images.<br />
      <br />
      For variants that are not visualized, the corresponding assignment is missing.<br />
      <br />
      On the right side the file_names are shown,
      which were searched for in the filenames of the images.
    </>),
  },
  'folderStructure.images.all': {
    'de': 'Varianten mit Bildern',
    'en': 'Variants with images',
  },
  'folderStructure.images.missing': {
    'de': 'Varianten ohne Bilder',
    'en': 'Variants without images',
  },
  'folderStructure.thumbs.description': {
    'de': (<>
      Eine Übersicht, welche Varianten Zuordnungen zu Thumbnails haben.
      In einem aufgeräumten Projekt sollten alle Varianten ein Thumbnail haben.<br />
      <br />
      Rechts werden die erwarteten Dateinamen angezeigt.
    </>),
    'en': (<>
      An overview of which variants have assignments to thumbnails.
      In a cleaned up project, all variants should have a thumbnail.<br />
      <br />
      On the right side the expected filenames are shown.
    </>),
  },
  'folderStructure.thumbs.all': {
    'de': 'Varianten mit Thumbnails',
    'en': 'Variants with thumbnails',
  },
  'folderStructure.thumbs.missing': {
    'de': 'Varianten ohne Thumbnails',
    'en': 'Variants without thumbnails',
  },
  'folderStructure.baseImages.description': {
    'de': (<>
      Eine Übersicht, welche Szenen Viewpoints mit jeweils einem Grundbild haben.
      In einem aufgeräumten Projekt sollten alle Szenen mindestens einen Viewpoint
      mit einem Grundbild haben.<br />
      <br />
      Rechts wird der erwartete Dateipfad angezeigt. Es werden png und webp unterstützt.
    </>),
    'en': (<>
      An overview of which scenes have a base image.
      In a cleaned up project, all scenes should have a base image.<br />
      <br />
      On the right side the expected filename is shown. The file formats png and webp are supported.
    </>),
  },
  'folderStructure.baseImages.all': {
    'de': 'Szenen mit Grundbild',
    'en': 'Scenes with base image',
  },
  'folderStructure.baseImages.missing': {
    'de': 'Szenen ohne Grundbild',
    'en': 'Scenes without base image',
  },
  'folderStructure.baseImages.hint': {
    'de': 'base.webp fehlt',
    'en': 'base.webp missing',
  },
  // mail
  'mail.configuration.subject': {
    'de': 'Ihre Konfiguration',
    'en': 'Your Configuration',
  },
  'mail.configuration.body': {
    'de': (<>
      Mit dem Zugangscode können Sie Ihre Konfiguration nochmals ansehen.<br />
      <br />
      <strong>Haben Sie sich bereits entschieden?</strong><br />
      Halten Sie den Code am nächsten Termin griffbereit
      oder leiten Sie dieses Mail an Ihre Betreuer:in weiter.
    </>),
    'en': (<>
      With the access code you can view your configuration again.<br />
      <br />
      <strong>Have you already decided?</strong><br />
      Keep the code ready for the next appointment
      or forward this email to your contact person.
    </>),
  },
  // offline
  'offline.title': {
    'de': 'Wir sind bald zurück!',
    'en': 'We will be back soon!',
  },
  'offline.message': {
    'de': (<>
      Wir arbeiten gerade auf Hochtouren, damit Sie bald Ihre Wohnung zusammenstellen können.<br />
      {/* Wir können Sie gerne informieren, sobald es los geht. */}
    </>),
    'en': (<>
      We are working hard to get you to be able to put together your apartment soon.<br />
      {/* We can keep you informed as soon as it starts. */}
    </>),
  },
  'offline.notifyMe': {
    'de': 'benachrichtigen Sie mich',
    'en': 'notify me',
  },
  'offline.configuration.title': {
    'de': 'Schon konfiguriert?',
    'en': 'Already configured?',
  },
  'offline.configuration.description': {
    'de': 'Ihre gespeicherte Konfiguration können Sie in der Zwischenzeit trotzdem ausdrucken oder online anzeigen.',
    'en': 'You can still print or display your saved configuration in the meantime.',
  },
  'offline.configuration.viewOnline': {
    'de': 'online anzeigen',
    'en': 'view online',
  },
  // onboarding
  'onboarding.home.title': {
    'de': 'Konfigurator',
    'en': 'Configurator',
  },
  'onboarding.title': {
    'de': 'Gestalten Sie Ihr Zuhause nach Ihrem Geschmack',
    'en': 'Design your home to your taste',
  },
  'onboarding.description1': {
    'de': 'Mit dem Tend Konfigurator können Sie Ihre Wohnung ganz nach Ihren Wünschen gestalten. Wählen Sie aus verschiedenen Ausstattungspaketen und individuellen Optionen. Sie können Ihre Konfiguration jederzeit speichern und später weiter bearbeiten. ',
    'en': 'With the Tend configurator you can design your apartment exactly to your wishes. Choose from different equipment packages and individual options. You can save your configuration at any time and edit it later.',
  },
  'onboarding.description2': {
    'de': 'Navigation',
    'en': 'Navigation',
  },
  'onboarding.description3': {
    'de': 'Mit einer Maus:',
    'en': 'Using a mouse:',
  },
  'onboarding.description4': {
    'de': 'Positionieren Sie den Mauszeiger irgendwo auf der Webseite. Rollen Sie das Scrollrad in der Mitte Ihrer Maus nach oben oder unten um die Räumlichkeiten zu wechseln.',
    'en': 'Place your mouse pointer anywhere on the webpage. Then, roll the scroll wheel in the middle of your mouse upwards or downwards to move between rooms.',
  },
  'onboarding.description5': {
    'de': 'Mit einem Touchscreen:',
    'en': 'Using a touchpad:',
  },
  'onboarding.description6': {
    'de': 'Berühren Sie den Bildschirm mit Ihrem Finger und ziehen Sie ihn nach oben oder unten um die Räumlichkeiten zu wechseln.',
    'en': 'Place two fingers on the touchpad and swipe up to scroll down the page, or swipe down to scroll up.',
  },
  'onboarding.openCode.button.explicit': {
    'de': 'Konfiguration laden',
    'en': 'load configuration',
  },
  'onboarding.openCode.button.minimal': {
    'de': 'code laden',
    'en': 'load code',
  },
  'onboarding.openCode.title': {
    'de': 'Konfiguration laden',
    'en': 'Load Configuration',
  },
  'onboarding.openCode.description': {
    'de': 'Sie haben bereits eine Konfiguration erstellt? Laden Sie diese mit dem Zugangscode.',
    'en': 'You have already created a configuration? Load it with the access code.',
  },
  'onboarding.openCode.placeholder': {
    'de': 'Zugangscode',
    'en': 'Access Code',
  },
  'onboarding.openReference.button': {
    'de': 'Referenzprojekt öffnen',
    'en': 'Open reference project',
  },
  'onboarding.designLine.cta': {
    'de': 'Designlinie wählen',
    'en': 'Choose design line',
  },
  'onboarding.moodImage.alt': {
    'de': 'Stimmungsbild',
    'en': 'Mood Image',
  },
  'restart.title': {
    'de': 'Neu starten',
    'en': 'Start again',
  },
  'restart.description': {
    'de': 'Möchten Sie Ihre Konfiguration wirklich neu starten? Bereits gespeicherte Konfigurationen mit Zugangscode bleiben erhalten.',
    'en': 'Do you really want to start your configuration again? Already saved configurations with access code remain.',
  },
  'save': {
    'de': 'speichern',
    'en': 'save',
  },
  'save.title': {
    'de': 'Konfiguration wurde gespeichert',
    'en': 'Configuration saved',
  },
  'save.description': {
    'de': 'Mit dem Zugangscode können Sie Ihre Konfiguration jederzeit wieder öffnen. Wenn sie Änderungen vornehmen und nochmals speichern, erhalten Sie einen neuen Code.',
    'en': 'With the access code you can open your configuration at any time. If you make changes and save again, you will receive a new code.',
  },
  'save.mail.title': {
    'de': 'Per E-Mail senden',
    'en': 'Send by E-Mail',
  },
  'save.mail.description': {
    'de': (<>
      Wir senden Ihre Konfiguration bequem per Mail.
      Mit dem Versenden der Konfiguration stimmen Sie unseren <a
        href={paths.PRIVACY}> Datenschutzbestimmungen </a> zu.
    </>),
    'en': (<>
      We will send your configuration by mail.
      By sending the configuration you agree to our <a
        href={paths.PRIVACY}> privacy policy </a>.
    </>),
  },
  'save.mail.placeholder': {
    'de': 'E-Mail Adresse',
    'en': 'E-Mail Address',
  },
  'saved.mail.text': {
    'de': 'Ihre Konfiguration wurde per E-Mail versendet.',
    'en': 'Your configuration has been sent by e-mail.',
  },
  'tool.brand': {
    'de': 'Tend',
    'en': 'Tend',
  },
  'tool.title': {
    'de': 'konfigurator (Tend)',
    'en': 'configurator (Tend)',
  },

  // --- errors ---

  'error.title': {
    'de': 'Oops!',
    'en': 'Oops!',
  },
  'error.message': {
    'de': 'Es tut uns leid, aber es sieht so aus, als wäre etwas schief gelaufen. Bitte versuchen Sie Folgendes',
    'en': 'We’re sorry, but it looks like something went wrong. Please try the following',
  },
  'error.suggestions.1': {
    'de': 'die Seite aktualisieren',
    'en': 'refresh the page',
  },
  'error.suggestions.2': {
    'de': 'Wenden Sie sich an den Support, wenn das Problem weiterhin besteht',
    'en': 'contact support if the problem persists',
  },
  'error.detailsTitle': {
    'de': 'Einzelheiten',
    'en': 'Details',
  },
};
export default translations;

export function formatPrice(price) {
  // Format the price to display it in the user's locale
  const priceFormatted = new Intl.NumberFormat(
    'de-CH',
    {
      style: 'currency',
      currency: 'CHF',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    },
  ).format(price).replace(/[a-z]{3}/i, '').trim();
  return `${priceFormatted} CHF`;
}
